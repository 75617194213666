<template>
  <div class="border border-solid border-blue-200">
    <div class="flex flex-col">
      <div class="duration-300">
        <datatable
          :data="settlements.data"
          :columns="columns"
          :fillable="false"
          :loading="settlements.loading"
          ref="table"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    query: String,
  },
  data() {
    return {
      settlements: this.$options.resource([]),
      columns: [
        {
          name: "merchant",
          th: "Merchant",
          render: (settlement) => settlement?.merchant?.name,
        },
        {
          name: "reference",
          th: "Reference",
        },
        {
					name: "settlement_reference",
					th: "Settlement Reference",
				},
        {
					name: "amount",
					th: "Settlement Amount",
					render: (settlement) => this.$options.filters.formatAmount(
						settlement?.amount,
					)
				},
        {
          name: "transaction_amount",
          th: "Transaction Amount",
          render: (settlement) => this.$options.filters.formatAmount(
            settlement?.transaction_amount,
          )
        },
        {
          name: "refund_amount",
          th: "Refund Amount",
          render: (settlement) => this.$options.filters.formatAmount(
            settlement?.refund_amount
          )
        },
        {
          name: "status",
          th: "Status",
          render: () => {
            return `<div class="badge badge-green-soft-outline px-7">
                  Paid
                </div>`
          }
        },
        {
          name: 'paid_at',
          th: 'Paid At',
          render: (settlement) =>
            this.$moment(settlement?.date).format('dddd, MMMM Do YYYY, h:mm:ss a'),
        },
      ],
    };
  },
  beforeMount() {
    this.paidSettlement();
  },
  methods: {
    async paidSettlement() {
      this.settlements.loading = true;
      await this.sendRequest("admin.merchants.payouts.paid", {
        success: (response) => {
          this.settlements.data = response.data.data;
        },
        error: (error) => {
          console.log(error);
        },
      });
      this.settlements.loading = false;
    },
  },
};
</script>